/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react'
import { gql } from '@apollo/client'
import moment from 'moment'
import { t } from 'i18next'

import {
  colorUtil,
  calendarUtil,
  multiSelectUtil,
  miscUtil,
  timeUtil,
  ApolloClientFetch,
  notification
} from '@app/util'
import {
  Icon,
  Locality,
  Position,
  Tooltip,
  ShiftAttribute,
  Text
} from '@ui'
import { ANNOUNCE_SHIFTS } from '@app/request/query'
import { requestAvailabilityDeleteMulti, requestTimeOffDeleteMulti } from '@app/request'

import './index.scss'
import connect from './connect'
import { formatHours } from '@app/util/format-hours'

function _getTimeOffIconSector (data, calendarFilters, isPast, isPluginEnabled) {
  const availOrTimeOff = calendarUtil.isEventAvailabilityOrTimeOff(data)
  // filter out warnings according to calendarFilters
  if (data.warnings && data.warnings.length) {
    const warnFilter = calendarFilters.find(f => f.hideWarnings)
    if (warnFilter && warnFilter.hideWarnings) {
      if (warnFilter.hideWarnings === 'all' || warnFilter.hideWarnings === true) {
        data.warnings = null
      } else {
        data.warnings = data.warnings.filter(w => !warnFilter || !warnFilter.hideWarnings || !warnFilter.hideWarnings.includes(w.name))
      }
    }
  }

  let icons = []
  icons.push(
    (availOrTimeOff === 'timeOff' && data.requestPendingAction && Icon.ICONS.question) ||
    (availOrTimeOff === 'timeOff' && data.attendanceApproved && Icon.ICONS.check) ||
    (((!isPast || isPluginEnabled('attendance')) && data?.warnings?.length) && Icon.ICONS.warning) ||
    null
  )
  icons = icons.filter(Boolean)
  if (data.note && availOrTimeOff === 'timeOff') {
    icons.push(Icon.ICONS.pin)
  }

  return (
    <div className={'ds-c-evt-flag' + (icons.length === 2 ? ' two-flags' : '')}>
      {icons.map((ic, idx) => (
        <Icon
          key={idx}
          ico={ic}
          size={Icon.SIZES.SMALL}
        />
      ))}
    </div>
  )
}

function _getShiftIconSector (data, params, isEmployeeCalendar, isPast, workspace, isPluginEnabled) {
  let content = null
  const hasTransferData = (data.hasOffer && data.hasOffer.transfer) || data.transfer
  const hasMaster = hasTransferData && hasTransferData.master
  const hasSlaves = hasTransferData && hasTransferData.slaves && hasTransferData.slaves.length

  let iconType = null
  let indicateShiftIsFromTransfer = false

  // =======================================================
  // Below is the decision tree that selects which icon type to render and whether we should also indicate the presence of transfer.
  // The actual rendering is further under this.
  // =======================================================
  if (isEmployeeCalendar) {
    // employee calendar
    if (data.userId === isEmployeeCalendar) {
      // my own shift
      if (data.note) {
        // shift has a note
        iconType = 'pin'
        indicateShiftIsFromTransfer = false
      } else {
        if (data.overTime) {
          // shift is overTime
          iconType = 'star'
          indicateShiftIsFromTransfer = false
        } else {
          const attribIcon = calendarUtil.getShiftAttributeIcon(data, workspace)
          if (attribIcon) {
            // no note, but there is an attribute icon
            iconType = '__attribicon_' + attribIcon
            indicateShiftIsFromTransfer = hasMaster
          } else {
            // no note and no attribute icon
            iconType = null
            indicateShiftIsFromTransfer = hasMaster
          }
        }
      }
    } else {
      // shift that's not mine at the moment (btw the fact that I see it in employeeCalendar means that there is an offer)
      if (data.hasOffer && data.hasOffer.notifyEmployees) {
        // offer with notification
        iconType = 'bell'
        indicateShiftIsFromTransfer = false
      } else {
        iconType = 'lock'
        indicateShiftIsFromTransfer = false
      }
    }
  } else {
    // manager calendar
    if (data.hasOffer) {
      // offer
      if (data.hasOffer.status === 'managerRequestApproval') {
        // offer request that needs approval (either from my employee or from external WS)
        if (data.hasOffer.type === 'swap') iconType = 'offerIconSwap' // swap icon
        if (data.hasOffer.type === 'drop') {
          if (data.userId) {
            iconType = 'offerIconDropAssign' // drop icon version for assigned shift
          } else {
            iconType = 'offerIconDropOffer' // drop icon - version for unassigned shift (auction)
          }
        }

        indicateShiftIsFromTransfer = hasMaster
      }

      if (data.hasOffer.status === 'employeeReplies') {
        // offer waiting for employee's replies
        iconType = 'offerReplyNumbers'
        indicateShiftIsFromTransfer = hasMaster || hasSlaves
      }

      if (data.hasOffer.status === 'managerResultApproval') {
        // offer that waits for manager's finalization
        iconType = 'questionMark'
        indicateShiftIsFromTransfer = hasMaster || hasSlaves
      }

      if (data.hasOffer.status === 'resolvedWaiting') {
        // external offer that was already resolved (candidate assigned), but it waits 2 hours before it's actually given to our employee (external emps can still get it in this period)
        iconType = 'transferIcon'
        indicateShiftIsFromTransfer = false // false because the iconType is already 'transferIcon' and we don't need two
      }
    } else {
      // no offer
      if (hasTransferData) {
        // is external transfer shift
        iconType = 'transferIcon'
        indicateShiftIsFromTransfer = false // false because the iconType is already 'transferIcon' and we don't need two
      } else {
        // is internal shift from my WS
        if ((!isPast || isPluginEnabled('attendance')) && data.warnings && data.warnings.length) {
          iconType = 'exclamation'
          indicateShiftIsFromTransfer = false
        } else {
          if (data.standBy) {
            if (data?.standByActivities?.length) {
              iconType = 'standbyWithActivities'
            } else {
              iconType = 'standby'
            }
            indicateShiftIsFromTransfer = false
          } else {
            if (data.note) {
              iconType = 'pin'
              indicateShiftIsFromTransfer = false
            } else {
              if (data.overTime) {
                iconType = 'star'
                indicateShiftIsFromTransfer = false
              } else {
                const attribIcon = calendarUtil.getShiftAttributeIcon(data, workspace)
                if (attribIcon) {
                  iconType = '__attribicon_' + attribIcon
                  indicateShiftIsFromTransfer = false
                } else {
                  iconType = null
                  indicateShiftIsFromTransfer = false
                }
              }
            }
          }
        }
      }
    }
  }

  // =======================================================
  // Actual rendering of the content we selected above.
  // =======================================================
  if (iconType === 'bell') {
    content = <Icon ico='bell' />
  } else if (iconType === 'lock') {
    content = <Icon ico='lock-open' />
  } else if (iconType === 'offerIconSwap') {
    content = <Icon ico={Icon.ICONS.switch} />
  } else if (iconType === 'offerIconDropAssign') {
    content = <Icon ico='assign' />
  } else if (iconType === 'offerIconDropOffer') {
    content = <Icon ico='offer' />
  } else if (iconType === 'offerReplyNumbers') {
    const wsOpts = workspace && workspace.settings && workspace.settings.offers && workspace.settings.offers && workspace.settings.offers
    content = (
      <Text
        type={Text.TYPES.BODY_SMALL}
        color={Text.COLORS.INHERIT}
        text={((wsOpts && wsOpts.managerApprovesResult) ? (data.hasOffer.announceAction.result.replies.filter(rep => rep.reply === 'yes').length.toString() + '/') : '') + [...new Set(data.hasOffer.announceAction.targetUsers)].length.toString()}
      />
    )
  } else if (iconType === 'questionMark') {
    content = <Icon ico='help' />
  } else if (iconType === 'transferIcon') {
    content = <Icon ico='exchange' />
  } else if (iconType === 'standby') {
    content = <Icon ico='phone' />
  } else if (iconType === 'standbyWithActivities') {
    content = <Icon ico='phone-plus' />
  } else if (iconType === 'pin') {
    content = <Icon ico='pin' size={Icon.SIZES.SMALL} />
  } else if (iconType === 'exclamation') {
    content = <Icon ico={Icon.ICONS.warning} />
  } else if (iconType && iconType.startsWith('__attribicon_')) {
    content = <Icon ico={iconType.slice(13)} />
  } else if (iconType === 'star') {
    content = <Icon ico={Icon.ICONS.star} />
  }

  if (indicateShiftIsFromTransfer) {
    content = <Fragment>{content}<Icon ico='exchange' /></Fragment>
  }

  return content
    ? <div className={'ds-c-evt-flag' + (indicateShiftIsFromTransfer ? ' is-two-flags' : '')}>{content}</div>
    : null
}

// this implements the logic of which modal should be opened for the view/editation of this event
// and also opens it. it should be called from the calendar (this file), but also from Tables with shifts (for example AttendanceTable)
function _openEventModal (eventData, eventType, props) {
  const {
    setModal,
    isEmployeeCalendar,
    isCycleCalendar,
    afterSubmit,
    cycleDetail,
    idx,
    setCycleDetailLastSaved,
    cycleGroups
  } = props
  if (eventType === 'shift' && !isCycleCalendar) {
    const hasTransferData = (eventData.hasOffer && eventData.hasOffer.transfer) || eventData.transfer
    const hasMaster = hasTransferData && hasTransferData.master
    // const hasSlaves = hasTransferData && hasTransferData.slaves && hasTransferData.slaves.length > 0
    const isReadOnly = hasMaster

    if (eventData.hasOffer && eventData.hasOffer.status !== 'resolved' && !props.isLockedDay) {
      setModal('offers', {
        action: eventData.hasOffer.status === 'managerRequestApproval'
          ? 'approve'
          : eventData.hasOffer.status === 'managerResultApproval'
            ? 'resolve'
            : 'show',
        selectedShiftIds: [eventData.id]
      })
    } else if (isReadOnly) {
      setModal('view-shift-as-employee', {
        shift: eventData.id,
        isEmployeeCalendar,
        overSidebar: isEmployeeCalendar
      })
    } else {
      setModal('edit-shift', {
        type: 'live',
        shift: eventData.id,
        // disabledEditation: hasSlaves,
        afterSubmit
      })
    }
  }
  if (eventType === 'shift' && isCycleCalendar) {
    const detail = cycleDetail.data.find(cycDetail => cycDetail.data.id === eventData.id)
    setModal('edit-shift', {
      type: 'cycle',
      shift: detail.data,
      newShift: false,
      groupId: cycleGroups[idx].groupId,
      afterSubmit: (p) => {
        if (p && p.data) setCycleDetailLastSaved(p.data)
      }
    })
  }
  if (eventType === 'availability' || eventType === 'unavailability' || eventType === 'timeOff') {
    setModal('edit-unavailability', {
      unavId: eventData.id,
      isEmployeeCalendar: isEmployeeCalendar,
      overSidebar: true,
      afterSubmit,
      processRequest: eventData.requestPendingAction ? true : undefined
    })
  }
}

// ========================================================================
// EVENT CLASS
// ========================================================================
class Event extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      contextMenu: false,
      isBeingDragged: false
    }
    this.getShiftHTML = this._getShiftHTML.bind(this)
    this.getShiftTemplateHTML = this._getShiftTemplateHTML.bind(this)
    this.getAvailOrTimeOffHTML = this._getAvailOrTimeOffHTML.bind(this)
    this.getTooltipContent = this._getTooltipContent.bind(this)
    this.dropShift = this._dropShift.bind(this)
    this.dropShiftTemplate = this._dropShiftTemplate.bind(this)
    this.handleCancelSelection = this._cancelSelection.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    // update if calendar view changes
    if (this.props.calendar.view !== nextProps.calendar.view) {
      return true
    }

    // update if event data changes
    if (miscUtil.hasEventChanged(this.props, nextProps)) {
      return true
    }

    // update if event's vertical shift changes
    if (this.props.verticalShift !== nextProps.verticalShift) {
      return true
    }

    // update if state.contextMenu changes
    if (this.state.contextMenu !== nextState.contextMenu) {
      return true
    }

    // update if state.isBeingDragged changes
    if (this.state.isBeingDragged !== nextState.isBeingDragged) {
      return true
    }

    // update if calendarFilters.hideWarnings changes
    const warningsFilterNow = this.props.calendarFilters ? this.props.calendarFilters.find(f => f.hideWarnings) : null
    const warningsFilterNext = nextProps.calendarFilters ? nextProps.calendarFilters.find(f => f.hideWarnings) : null
    if (warningsFilterNow !== warningsFilterNext) {
      return true
    }

    // update if store.positions are loaded
    if (nextProps.positions && nextProps.positions.length && (!this.props.positions || !this.props.positions.length)) {
      return true
    }

    // update if event gets multiselected, deselected, or if selection changes in a related way, while it's in it
    if (this.props.calendarMultiSelect !== nextProps.calendarMultiSelect) {
      if (this.props.calendarMultiSelect.isSelectingTargets !== nextProps.calendarMultiSelect.isSelectingTargets) return true

      if (Array.isArray(this.props?.calendarMultiSelect?.sourceEvents) && Array.isArray(nextProps?.calendarMultiSelect?.sourceEvents)) {
        const nowPropsIdx = this.props.calendarMultiSelect.sourceEvents.findIndex(ev => ev.id === this.props.data.id)
        const nextPropsIdx = nextProps.calendarMultiSelect.sourceEvents.findIndex(ev => ev.id === this.props.data.id)
        if (nowPropsIdx !== nextPropsIdx || nowPropsIdx !== -1 || nextPropsIdx !== -1) return true
      }
    }

    // update if calendar's sortedBy setting changed
    if (this.props.sortedBy !== nextProps.sortedBy) {
      return true
    }

    return false
  }

  componentDidUpdate (prevProps) {
    // when this event got deselected (via Schedule component's functionality), make sure it's state.contextMenu is false
    if (prevProps?.calendarMultiSelect?.sourceEvents?.some(evt => evt.id === prevProps?.data?.id) && !this.props?.calendarMultiSelect?.sourceEvents?.some(evt => evt.id === this.props?.data?.id)) {
      if (this.state.contextMenu) {
        this.setState(s => Object.assign({}, s, { contextMenu: false }))
      }
    }
  }

  _cancelSelection = (e) => {
    e.stopPropagation()
    e.preventDefault()
    multiSelectUtil.multiSelectClear()
  }

  // handle dropping a shift into some calendar cell
  _dropShift (e) {
    const sf = this.props.data
    const view = this.props.calendar.view

    // find out where it was dropped
    let pageX = e.pageX && e.pageX !== 0 ? e.pageX : window.dragPageX
    let pageY = e.pageY && e.pageY !== 0 ? e.pageY : window.dragPageY
    pageX = parseFloat(pageX)
    pageY = parseFloat(pageY)
    if (isNaN(pageX)) pageX = 0.0
    if (isNaN(pageY)) pageY = 0.0
    const droppedOn = document.elementsFromPoint(pageX, pageY).find((el) => el.classList.contains('ds-droppable'))

    if (!droppedOn) return
    const dropData = JSON.parse(droppedOn.dataset.droppable)

    const update = {}
    let startOffer = false

    // prepare the update
    const currentEmployee = sf.userId
    const newEmployee = dropData.employee
    if (currentEmployee !== newEmployee) {
      if (newEmployee === 'unassigned') {
        update.userId = null
      } else if (newEmployee === 'offers') {
        if (!sf.hasOffer || sf.hasOffer.status === 'resolved') {
          startOffer = true
        }
        delete update.userId
      } else {
        update.userId = newEmployee
      }
    }

    // prepare period update when dropped in a day (month or week view)
    if (view === 'month' || view === 'week') {
      if (!this.props.calendar.date) return
      const currentDay = moment(sf.period.start)
      const newDay = moment(this.props.calendar.date).startOf(view).add(dropData.colIdx, 'days')
      const dur = moment(sf.period.end).diff(sf.period.start) / 1000 / 60
      if (!currentDay.isSame(newDay, 'day')) {
        const newPeriod = Object.assign({}, sf.period)
        newPeriod.start = currentDay.clone().date(newDay.date()).month(newDay.month()).year(newDay.year())
        newPeriod.end = newPeriod.start.clone().add(dur, 'minutes')
        update.period = newPeriod
      }
    }

    // prepare period update when dropped in an hour (day view)
    if (view === 'day') {
      const currentHour = moment(sf.period.start)
      const newHour = moment(this.props.calendar.date).startOf(view).add(dropData.colIdx, 'hours')
      const dur = moment(sf.period.end).diff(sf.period.start) / 1000 / 60
      if (!currentHour.isSame(newHour, 'hour')) {
        const newPeriod = Object.assign({}, sf.period)
        newPeriod.start = currentHour.clone().hour(newHour.hour()).minute(newHour.minute())
        newPeriod.end = newPeriod.start.clone().add(dur, 'minutes')
        update.period = newPeriod
      }
    }

    const shouldUpdate = typeof update.period !== typeof undefined || typeof update.userId !== typeof undefined

    // open the 'offers' modal
    if (startOffer) {
      const up = {}
      if (update.period) up.period = update.period
      if (update.userId && update.userId !== 'offers') up.userId = update.userId
      this.props.setModal('offers', {
        selectedShiftIds: [sf.id],
        action: 'create',
        createUpdateBefore: (up.period || up.userId) ? up : undefined
      })

      // hide open event's tooltip after we one the 'offers' modal so it doesn't cover it
      setTimeout(() => {
        const tt = document.querySelector('.evt-tt-' + sf.id)
        if (tt) tt.style.display = 'none'
      }, 250)
    } else {
      if (shouldUpdate) this.props.updateLiveShift(sf.id, update)
    }
  }

  // handle dropping a shiftTemplate into some calendar cell
  _dropShiftTemplate (e, tpl) {
    const { createShift, calendar, setModal } = this.props

    // find out where it was dropped
    let pageX = e.pageX && e.pageX !== 0 ? e.pageX : window.dragPageX
    let pageY = e.pageY && e.pageY !== 0 ? e.pageY : window.dragPageY
    pageX = parseFloat(pageX)
    pageY = parseFloat(pageY)
    if (isNaN(pageX)) pageX = 0.0
    if (isNaN(pageY)) pageY = 0.0
    const droppedOn = document.elementsFromPoint(pageX, pageY).find((el) => el.classList.contains('ds-droppable'))

    if (!droppedOn) return
    const dropData = JSON.parse(droppedOn.dataset.droppable)

    // create shift from template
    const day = moment(calendar.date).startOf(calendar.view).add(calendar.view === 'day' ? 0 : dropData.colIdx, 'days').format('YYYY-MM-DD')
    calendarUtil.createShiftFromShiftTemplate(
      tpl,
      (dropData.employee && dropData.employee !== 'unassigned' && dropData.employee !== 'offers') ? dropData.employee : null,
      day,
      createShift,
      (res) => {
        if (dropData.employee === 'offers' && res && res.length && res[0].id) {
          const newShiftId = res[0].id
          setModal('offers', {
            selectedShiftIds: [newShiftId],
            newlyCreatedShifts: res,
            action: 'create',
            createType: 'drop'
          })
        }
      }
    )
  }

  _isActiveColContextMenu () {
    return !!document.querySelector('.ds-c-sections .ds-c-col > .tooltip')
  }

  _getShiftHTML (data, params) {
    const {
      key,
      positions,
      setModal,
      day,
      isExternalEmployeeRow,
      isLockedDay,
      calendarMultiSelect,
      setCalendar,
      setCalendarMultiSelect,
      isEmployeeCalendar,
      isTransparent,
      workspace,
      onDoubleClick,
      calendarFilters,
      className,
      isPluginEnabled,
      isFirstShiftInDay,
      isCycleCalendar,
      isCyclePreview
    } = this.props
    if (isEmployeeCalendar) params.view = 'day'
    if (isCycleCalendar) params.view = 'month'
    const pos = positions.find((p) => p.id === data.positionId)
    const bgColor = (pos && pos.color)
      ? pos.color
      : (pos && pos.id)
        ? colorUtil.idToColor(pos.id, positions)
        : '666666'
    const txtColor = colorUtil.contrastText(bgColor)
    const isPast = moment(data.period.start).isBefore(moment().startOf('day'))

    const hasTransferData = (data.hasOffer && data.hasOffer.transfer) || data.transfer
    const hasMaster = hasTransferData && hasTransferData.master
    const isReadOnly = hasMaster || isLockedDay || isExternalEmployeeRow
    const isNotDraggable = isReadOnly || (data.hasOffer && data.hasOffer.status !== 'resolved') || isCycleCalendar

    // filter out warnings according to calendarFilters
    if (data.warnings && data.warnings.length) {
      const warnFilter = calendarFilters.find(f => f.hideWarnings)

      if (warnFilter && warnFilter.hideWarnings) {
        if (warnFilter.hideWarnings === 'all' || warnFilter.hideWarnings === true) {
          data.warnings = null
        } else {
          data.warnings = data.warnings.filter(w => !warnFilter || !warnFilter.hideWarnings || !warnFilter.hideWarnings.includes(w.name))
        }
      }
    }

    const iconSectorContent = _getShiftIconSector(data, params, isEmployeeCalendar, isPast, workspace, isPluginEnabled)

    let posText = ''
    if (hasMaster && hasMaster.positionName) {
      posText = hasMaster.positionName
    } else {
      posText = pos ? pos.name : t('SORT_BY_POSITION_NO_POS')
      const metaTxt = calendarUtil.getShiftMetaText(data, workspace)
      if (metaTxt) {
        posText += (params.view !== 'month' && posText !== '') ? ' (' + metaTxt + ')' : ' ' + metaTxt
      }
    }

    let flagNotMyShift = false
    if (isEmployeeCalendar) {
      if (!data.userId || isEmployeeCalendar !== data.userId) {
        flagNotMyShift = true
      }
    }

    if (data.published && (!data.plannedByPlanner || data.plannedByPlanner === false)) {
      params.customStyles.backgroundColor = '#' + bgColor // colors for published shifts, not plannedByPlanner
      params.customStyles.borderColor = '#' + bgColor
    } else if (isCycleCalendar) {
      params.customStyles.backgroundColor = '#' + bgColor // colors for cycle calendar
      params.customStyles.borderColor = '#' + bgColor
    } else {
      params.customStyles.borderColor = '#' + bgColor // colors for unpublished shifts or shifts plannedByPlanner
      params.customStyles.color = '#' + bgColor
    }

    const displayPauses = params.view === 'day' && data.pauses?.length
    const displayAgenda = params.view === 'day' && data.agenda?.length && isPluginEnabled('agenda')
    const displayStandByActivities = params.view === 'day' && data.standByActivities?.length && isPluginEnabled('standby')
    const thisIsTopSectionRow = ['offers', 'unassigned'].includes(params.rowEmployee)
    const evtIdentifier = calendarUtil.getEvtIdentifier(data.id, ((isEmployeeCalendar && data.period) ? data.period.start : day), false)

    const durationMins = moment(data.period.end).diff(data.period.start, 'minutes')
    const timeDivider = (params.view === 'month') ? <br /> : ' '
    const timeString = (durationMins > 90 || ['month', 'week'].includes(params.view))
      ? <Fragment>{moment(data.period.start).format('H:mm')}&nbsp;-{timeDivider}{moment(data.period.end).format('H:mm')}</Fragment>
      : <Fragment>{moment(data.period.start).format('H:mm')}</Fragment>

    // if there are multiple shifts with this same ID, keep only one tooltip visible (happens when a shift has an ongoing offer or when it's displayed in multiple contract/position sections in calendar)
    const dealWithMultipleCopiesOfSameShift = (e) => {
      const targetAncestor = e.target.closest('.ds-c-col')

      setTimeout(() => {
        var found = document.querySelectorAll('.evt-tt.evt-tt-' + data.id)
        for (let i = 0; i < found.length; ++i) {
          if (found[i].closest('.ds-c-col') !== targetAncestor) {
            found[i].style.display = 'none'
          }
        }
      }, 50)
    }

    return (
      <div
        data-testid={`shift-event-${data.id}`}
        className={
          'ds-c-event ' +
          (isFirstShiftInDay ? 'is-first-shift ' : '') +
          (className ? (className + ' ') : '') +
          'is-text-' + txtColor +
          ' is-shift ' +
          (data.parentId ? 'is-standby-actvity ' : '') +
          (data.published ? 'is-published ' : 'is-unpublished ') +
          (data.plannedByPlanner ? 'is-planned-by-planner ' : '') +
          (isTransparent ? 'is-transparent ' : '') +
          (thisIsTopSectionRow ? 'is-top-section ' : '') +
          (iconSectorContent ? 'has-flag ' : '') +
          (durationMins < 150 ? 'is-short-duration ' : '') +
          (hasMaster ? 'is-external-offer ' : '') +
          (data.hasOffer && data.hasOffer.transfer && data.hasOffer.transfer.slaves && data.hasOffer.transfer.slaves.length ? 'is-local-offer ' : '') +
          (data.hasOffer && data.hasOffer.status !== 'resolved' ? 'has-offer ' : '') +
          (data.hasOffer && data.hasOffer.announceAction ? 'is-offer-announced ' : '') +

          (flagNotMyShift ? 'is-not-my-shift ' : '') +
          ' is-view-' + params.view +
          ' ' + evtIdentifier +
          (params.isEventMultiSelected && !isCyclePreview && !isCycleCalendar ? ' is-ms-selected' : '') +
          (isCyclePreview ? ' is-preview' : '') +
          (params.isEventMultiSelected && params.isEvtMSSingle && !isCyclePreview ? ' is-mss-single' : '')
        }
        key={key}
        style={Object.assign({ zIndex: 2 }, params.customStyles)}
        draggable={!isEmployeeCalendar && !isNotDraggable}
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', data.id)

          // when dragging a shift, make sure it's selected
          if (!isEmployeeCalendar && !isCycleCalendar && params.isMultiSelectEnabled && !params.isEventMultiSelected) {
            setCalendarMultiSelect({
              action: null,
              targets: [],
              sourceEvents: [data],
              isSelectingTargets: false
            })
          }

          this.setState(s => Object.assign({}, s, { isBeingDragged: true }))
        }}
        onDragEnd={(e) => {
          this.dropShift(e)
          this.setState(s => Object.assign({}, s, { isBeingDragged: false }))
        }}
        onClick={(e) => {
          if (isCyclePreview) return
          e.stopPropagation() // this needs to be here so the click on the Event doesn't count as a click on the Column (clicking column de-selects events)

          // double-click is here, but but it's disabled when there is more than one shift multiselected or when ctrl/shift/meta key is pressed
          if (!((params.isEventMultiSelected && !params.isEvtMSSingle) || (e.ctrlKey || e.metaKey || e.shiftKey))) {
            onDoubleClick(() => { _openEventModal(data, this.props.type, this.props) })
          }

          // deselect the event if it's the only one selected and we click on it again
          if (params.isEvtMSSingle) {
            this.handleCancelSelection(e)
            return
          }

          // multi-select this event
          if (!isEmployeeCalendar) {
            if (params.isMultiSelectEnabled) {
              // if CTRL or SHIFT was pressed when clicking, add this event to the selection (or remove it).
              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                let addToSelection = null
                let removeFromSelection = null
                if (calendarMultiSelect.sourceEvents.find(src => src.id === data.id)) {
                  removeFromSelection = data.id
                } else {
                  addToSelection = Object.assign({}, data, { day })
                }
                setCalendarMultiSelect({
                  action: null,
                  targets: [],
                  isSelectingTargets: false
                }, addToSelection, removeFromSelection)
              } else {
                // otherwise, select only this one event
                setCalendarMultiSelect({
                  action: null,
                  sourceEvents: [Object.assign({}, data, { day })],
                  targets: [],
                  isSelectingTargets: false
                })
              }

              if (this.state.contextMenu) this.setState(s => Object.assign({}, s, { contextMenu: false }))
              if (this._isActiveColContextMenu()) setCalendar({ contextMenuForColumn: undefined })
            } else {
              _openEventModal(data, 'shift', this.props)
            }

            // if there are 2 shifts with this same ID (because this is a shift with ongoing offer), keep only one tooltip visible
            dealWithMultipleCopiesOfSameShift(e)
          } else {
            setModal('view-shift-as-employee', {
              shift: data.id,
              overSidebar: true,
              isEmployeeCalendar
            })
          }
        }}
        onContextMenu={(e) => { // right-click
          if (isCyclePreview) return
          if (!isEmployeeCalendar) {
            e.stopPropagation()
            e.preventDefault()
            setCalendarMultiSelect({
              action: null,
              sourceEvents: calendarMultiSelect?.sourceEvents?.some(ev => ev.id === data.id)
                ? calendarMultiSelect.sourceEvents // if this right-clicked event is already multiselected, don't do anything with the selection
                : [Object.assign({}, data, { day })], // if this event is not multiselected, make it selected
              targets: [],
              isSelectingTargets: false
            })
            if (!this.state.contextMenu) this.setState(s => Object.assign({}, s, { contextMenu: true }))

            // if there are 2 shifts with this same ID (because this is a shift with ongoing offer), keep only one tooltip visible
            dealWithMultipleCopiesOfSameShift(e)
          }
        }}
      >
        {data.agenda && data.agenda.length ? (
          <div className='agenda-icon'>
            <Icon
              ico={Icon.ICONS.save}
              color={params.isEventMultiSelected ? Icon.COLORS.WHITE : Icon.COLORS.INHERIT}
              size={Icon.SIZES.SMALL}
            />
          </div>
        ) : null}
        {displayPauses
          ? data.pauses.map((p, pidx) => {
            const widthPercent = p.duration / data.duration * 100
            const leftPercent = p.start / data.duration * 100
            return (
              <div
                key={data.id + 'p' + pidx.toString()}
                className='ds-c-evt-pause'
                style={{ left: (leftPercent).toString() + '%', width: (widthPercent).toString() + '%' }}
              >
                {p.start >= 180 ? t('PAUSE_ON_EVENT') : null}
              </div>
            )
          })
          : null}

        {displayAgenda
          ? data.agenda.map((ag, pidx) => {
            const widthPercent = ag.duration / data.duration * 100
            const leftPercent = ag.start / data.duration * 100
            return (
              <div
                key={data.id + 'ag' + pidx.toString()}
                className='ds-c-evt-agenda'
                style={{
                  left: (leftPercent).toString() + '%',
                  width: 'calc(' + (widthPercent).toString() + '% - 1px)'
                }}
              >
                {ag.start >= 180 ? <span>{ag.name}</span> : null}
              </div>
            )
          })
          : null}

        {displayStandByActivities
          ? data.standByActivities.map((ag, pidx) => {
            const widthPercent = ag.duration / data.duration * 100
            const leftPercent = ag.start / data.duration * 100
            return (
              <div
                key={data.id + 'ag' + pidx.toString()}
                className='ds-c-evt-agenda'
                style={{
                  left: (leftPercent).toString() + '%',
                  width: 'calc(' + (widthPercent).toString() + '% - 1px)'
                }}
              />
            )
          })
          : null}

        <div className='ds-c-evt-time'>
          {timeString}
          {displayPauses && !isEmployeeCalendar
            ? data.pauses.length === 1
              ? ', ' + t('PAUSE_SHORT_X', {
                dur: data.pauses[0].duration.toString() + 'm',
                start: moment(data.period.start).add(data.pauses[0].start, 'minutes').format('H:mm')
              })
              : ', ' + data.pauses.length.toString() + ' ' + t('PAUSES_SHORT').toLowerCase()
            : null}
        </div>

        {iconSectorContent}
        <div className='ds-c-evt-position'>{posText}</div>
      </div>
    )
  }

  _getShiftTemplateHTML (data, params) {
    const {
      setModal,
      setCalendar,
      setCalendarMultiSelect,
      calendarMultiSelect,
      workspace,
      onDoubleClick,
      calendar,
      positions
    } = this.props

    const isTplSelected = calendarMultiSelect?.sourceEvents?.find(evt => evt.id === data.id)
    const iconSectorContent = _getShiftIconSector(data, { customStyles: { color: 'fff' } }, false, false, workspace)
    const pos = workspace && positions && positions.find(p => p.id === data.positionId)
    const bgColor = (pos && pos.color)
      ? pos.color
      : (pos && pos.id)
        ? colorUtil.idToColor(pos.id, positions)
        : '666666'
    const displayPauses = calendar.view === 'day' && data.pausesFixed && data.pauses && data.pauses.length
    const durationMins = moment(data.period.end).diff(data.period.start, 'minutes')
    const timeString = (durationMins > 90 || ['month', 'week'].includes(calendar.view))
      ? <Fragment>{moment(data.period.start).format('H:mm')}&nbsp;- {moment(data.period.end).format('H:mm')}</Fragment>
      : <Fragment>{moment(data.period.start).format('H:mm')}</Fragment>

    const shiftTemplateObject = {
      id: data.id,
      name: data.shiftTemplateName,
      shift: Object.assign({}, data, { id: undefined, shiftTemplateName: undefined })
    }

    return (
      <div
        className={
          'ds-c-event ' +
          'is-text-light ' +
          (calendar.view === 'week' ? 'is-view-week ' : 'is-view-month ') +
          (iconSectorContent ? 'has-flag ' : '') +
          (isTplSelected ? 'is-ms-selected ' : '')
        }
        style={{
          backgroundColor: '#' + bgColor,
          borderColor: '#' + bgColor
        }}
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', data.id)

          // when dragging a shiftTemplate, make sure it's selected
          if (!isTplSelected) {
            setCalendarMultiSelect({
              sourceEvents: [shiftTemplateObject],
              targets: []
            })
          }

          this.setState(s => Object.assign({}, s, { isBeingDragged: true }))
        }}
        onDragEnd={(e) => {
          this.dropShiftTemplate(e, shiftTemplateObject)
          this.setState(s => Object.assign({}, s, { isBeingDragged: false }))
        }}
        onClick={(e) => {
          onDoubleClick(() => { setModal('edit-shift-template', { shiftTemplateId: shiftTemplateObject.id }) })

          setCalendarMultiSelect({
            action: null,
            sourceEvents: [shiftTemplateObject],
            targets: [],
            isSelectingTargets: false
          })

          if (this._isActiveColContextMenu()) setCalendar({ contextMenuForColumn: undefined })
        }}
        onContextMenu={(e) => { // right-click
          e.stopPropagation()
          e.preventDefault()
          setCalendarMultiSelect({
            action: null,
            sourceEvents: calendarMultiSelect?.sourceEvents?.some(ev => ev.id === data.id)
              ? calendarMultiSelect?.sourceEvents // if this right-clicked event is already multiselected, don't do anything with the selection
              : [shiftTemplateObject], // if this event is not multiselected, make it selected
            targets: [],
            isSelectingTargets: false
          })
          if (!this.state.contextMenu) this.setState(s => Object.assign({}, s, { contextMenu: true }))
        }}
      >
        <div className='ds-c-evt-time'>
          {timeString}

          {data.agenda && data.agenda.length ? (
            <div className='agenda-icon'>
              <Icon
                ico={Icon.ICONS.save}
                color={params.isEventMultiSelected ? Icon.COLORS.WHITE : Icon.COLORS.INHERIT}
                size={Icon.SIZES.SMALL}
              />
            </div>
          ) : null}

          {displayPauses
            ? data.pauses.length === 1
              ? ', ' + t('PAUSE_SHORT_X', { dur: data.pauses[0].duration.toString() + 'm', start: moment(data.period.start).add(data.pauses[0].start, 'minutes').format('H:mm') })
              : ', ' + data.pauses.length.toString() + ' ' + t('PAUSES_SHORT').toLowerCase()
            : null}
        </div>

        {iconSectorContent}

        <div className='ds-c-evt-position'>
          {shiftTemplateObject.name}
        </div>
      </div>)
  }

  _getAvailOrTimeOffHTML (data, params) {
    const {
      key,
      day,
      isEmployeeCalendar,
      workspace,
      setCalendar,
      setCalendarMultiSelect,
      calendarMultiSelect,
      onDoubleClick,
      className,
      calendarFilters,
      isPluginEnabled
    } = this.props
    const isWholeDay = moment(data.period.start).format('HH:mm') === '00:00' && (moment(data.period.end).format('HH:mm') === '00:00' || moment(data.period.end).format('HH:mm') === '23:59')
    const category = calendarUtil.getTimeOffOrAvailCategory(data, workspace)
    const detailedCategory = workspace.unavailabilityCategories.find(uc => uc.id === data.categoryId)
    const displayAsDays = detailedCategory?.displayWorkTimeAsDays
    const availOrTimeOff = calendarUtil.isEventAvailabilityOrTimeOff(data)
    const timeOffTotalHours = availOrTimeOff === 'timeOff' && data.workMinutes && data.workMinutes / 60
    const isEvenHourStart = moment(data.period.start).format('mm') === '00'
    const isEvenHourEnd = moment(data.period.end).format('mm') === '00'
    const periodString = moment(data.period.start).format(isEvenHourStart ? 'H' : 'H:mm') + '-' + moment(data.period.end).format(isEvenHourEnd ? 'H' : 'H:mm')
    const isPast = moment(data.period.start).isBefore(moment().startOf('day'))

    const getTimeOffLabel = () => {
      if (displayAsDays) {
        return data.workDays + t('DAY_SHORTEST')
      }

      return formatHours(timeOffTotalHours)
    }

    const durationString = category?.displayWorkTimeAsDays
      ? data.workDays + t('DAY_SHORTEST')
      : getTimeOffLabel()

    if (isEmployeeCalendar) params.view = 'day'

    const ico = _getTimeOffIconSector(data, calendarFilters, isPast, isPluginEnabled)

    return (
      <div
        data-testid={`timeoff-event-${data.id}`}
        className={
          'ds-c-event' +
          (className ? (className + ' ') : '') +
          ' is-unavailability' +
          ' is-mode-' + (data.available ? 'available' : 'unavailable') +
          ' is-view-' + params.view +
          ' is-' + (availOrTimeOff === 'timeOff' ? 'HARD' : 'SOFT') +
          (isWholeDay ? ' is-whole-day' : '') +
          ' ' + calendarUtil.getEvtIdentifier(data.id, day) +
          (params.isEventMultiSelected ? ' is-ms-selected' : '') +
          (params.isEventMultiSelected && params.isEvtMSSingle ? ' is-mss-single' : '') +
          (data.workMinutes > 0 && !params.isEventMultiSelected ? ' is-taking-total-hours' : '')
        }
        key={key}
        style={Object.assign({ zIndex: 2 }, params.customStyles)}
        onClick={(e) => {
          e.stopPropagation() // this needs to be here so the click on the Event doesn't count as a click on the Column (clicking column de-selects events)

          // double-click is here, but but it's disabled when there is more than one shift multiselected or when ctrl/shift/meta key is pressed
          if (!((params.isEventMultiSelected && !params.isEvtMSSingle) || (e.ctrlKey || e.metaKey || e.shiftKey))) {
            onDoubleClick(() => { _openEventModal(data, availOrTimeOff, this.props) })
          }

          // deselect the event if it's the only one selected and we click on it again
          if (params.isEvtMSSingle) {
            this.handleCancelSelection(e)
            return
          }

          if (!isEmployeeCalendar) {
            if (params.isMultiSelectEnabled) {
              // if CTRL or SHIFT was pressed when clicking, add this event to the selection (or remove it).
              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                let addToSelection = null
                let removeFromSelection = null
                if (calendarMultiSelect.sourceEvents.find(src => src.id === data.id)) {
                  removeFromSelection = data.id
                } else {
                  addToSelection = Object.assign({}, data, { day })
                }
                setCalendarMultiSelect({
                  action: null,
                  targets: [],
                  isSelectingTargets: false
                }, addToSelection, removeFromSelection)
              } else {
                // otherwise, select only this one event
                setCalendarMultiSelect({
                  action: null,
                  sourceEvents: [Object.assign({}, data, { day })],
                  targets: [],
                  isSelectingTargets: false
                })
              }

              if (this._isActiveColContextMenu()) setCalendar({ contextMenuForColumn: undefined })
            } else {
              _openEventModal(data, availOrTimeOff, this.props)
            }
          } else {
            _openEventModal(data, availOrTimeOff, this.props)
          }
        }}
        onContextMenu={(e) => { // right-click
          if (!isEmployeeCalendar) {
            e.stopPropagation()
            e.preventDefault()
            setCalendarMultiSelect({
              action: null,
              sourceEvents: calendarMultiSelect?.sourceEvents?.some(ev => ev.id === data.id)
                ? calendarMultiSelect.sourceEvents // if this right-clicked event is already multiselected, don't do anything with the selection
                : [Object.assign({}, data, { day })], // if this event is not multiselected, make it selected
              targets: [],
              isSelectingTargets: false
            })
            if (!this.state.contextMenu) this.setState(s => Object.assign({}, s, { contextMenu: true }))
          }
        }}
      >

        <div className='ds-c-evt-time ds-c-evt-unav-txt'>
          {isWholeDay ? (
            params.view === 'month' ? t('CAL_NA_ALL_DAY_SHORT') : t('ALL_DAY')
          ) : (
            <span title={(!isEvenHourStart || !isEvenHourEnd) ? periodString : undefined}>
              {periodString}
            </span>
          )}
          {params.view === 'month' ? <br /> : <span>&nbsp;</span>}
          {availOrTimeOff === 'timeOff' && durationString}
        </div>

        {ico}

        {availOrTimeOff === 'timeOff' && (
          <div className='ds-c-evt-position'>
            {(params.view === 'month' && category.tag && category.tag.length)
              ? category.tag
              : category.name}
          </div>
        )}
      </div>
    )
  }

  _getTooltipContent (data, type) {
    const {
      positions,
      workspace,
      calendarMultiSelect,
      setCalendarMultiSelect,
      setModal,
      setSidebar,
      createPlan,
      deleteLiveShift,
      updateLiveShift,
      updateShiftTemplate,
      auth,
      isPluginEnabled,
      isLockedDay,
      isCycleCalendar,
      deleteCycleDetail,
      setCycleDetailLastSaved
    } = this.props
    const topSectionRows = []
    const hasTransferData = (data.hasOffer && data.hasOffer.transfer) || data.transfer
    const hasMaster = hasTransferData && hasTransferData.master

    // SHIFT or SHIFT TEMPLATE
    if (type === 'shift' || type === 'shiftTemplate') {
      // work time
      const time = data?.stats?.length
        ? timeUtil.formatMinutes(data?.stats?.reduce((a, s) => { return a + s.workMinutes }, 0))
        : timeUtil.formatMinutes(
          moment(data?.period?.end).diff(data?.period?.start, 'minutes') - (data?.pausesFixed
            ? data?.pauses?.reduce((a, s) => { return a + s.duration }, 0)
            : (timeUtil.getDefaultShiftBreaks({ details: data, workspace })?.duration || 0))
        )

      topSectionRows.push(
        <div className='evt-tt-r-duration' key={topSectionRows.length}>
          <span className='k'>{t('ATTEN_DURATION')}</span>
          <span
            className='v'
          >{time}
          </span>
        </div>
      )

      // pauses
      if (data.pauses) {
        let pauseTxt
        if (data.pauses.length >= 1) {
          data.pauses.forEach(p => {
            pauseTxt = t('PAUSE_SHORT_X', {
              dur: p.duration.toString() + 'm',
              start: moment(data.period.start).add(p.start, 'minutes').format('H:mm')
            })
            topSectionRows.push(
              <div key={topSectionRows.length}>
                <span className='k'>{t('EVT_TOOLTIP_PAUSE')}</span>
                <span className='v'>{pauseTxt}</span>
              </div>)
          })
        }
        if (data.pauses && data.pauses.length === 0 && data.pausesFixed) {
          pauseTxt = t('PAUSE_NO_PAUSE')
          topSectionRows.push(
            <div key={topSectionRows.length}>
              <span className='k'>{t('EVT_TOOLTIP_PAUSE')}</span>
              <span className='v'>{pauseTxt}</span>
            </div>)
        }
      }

      // position
      const pos = positions.find((p) => p.id === data.positionId)
      topSectionRows.push(
        <div key={topSectionRows.length}>
          <span className='k'>{t('POSITION')}</span>
          <span className='v'>
            <Position {...pos} skill={data.idealSkill} />
          </span>
        </div>
      )

      // locality
      if (data.localityId && workspace && workspace.localities) {
        const loc = workspace.localities.find(l => l.id === data.localityId)
        if (loc) topSectionRows.push(<div key={topSectionRows.length}><span className='k'>{t('LOCALITY')}</span><Locality {...loc} /></div>)
      }

      // published
      if (!isCycleCalendar && type === 'shift') {
        topSectionRows.push(
          <div className='evt-tt-r-status' key={topSectionRows.length}>
            <span className='k'>{t('EVT_TOOLTIP_STATUS')}</span>
            <span className='v'>{t(data.published ? 'EVT_TOOLTIP_PUBLISHED' : 'EVT_TOOLTIP_NOT_PUBLISHED')}</span>
          </div>
        )
      }

      // warnings
      if (data.warnings && data.warnings.length) {
        topSectionRows.push(
          <div className='evt-tt-r-warnings' key={topSectionRows.length}>
            <span className='k'>{t('EVT_TOOLTIP_WARNINGS')}</span>
            <span className='v'>
              <div
                className='v-warn' style={{ maxWidth: (data.warnings.length === 1) ? '5.65rem' : '5rem' }}
                title={t('WARNING_' + data.warnings[0].name)}
              >{t('WARNING_' + data.warnings[0].name)}
              </div>
              {data.warnings.length > 1 && (
                <div className='v-warn'>+{(data.warnings.length - 1)}</div>
              )}
            </span>
          </div>
        )
      }

      // note
      if (data.note) {
        topSectionRows.push(
          <div className='evt-tt-r-note' key={topSectionRows.length}>
            <span className='k'>{t('NOTE')}</span>
            <span className='v'><div>{data.note}</div></span>
          </div>
        )
      }

      // shift attributes
      const attribs = calendarUtil.getShiftAttributes(data, workspace)
      if (attribs && attribs.length) {
        topSectionRows.push(
          <div className='evt-tt-r-attribs' key={topSectionRows.length}>
            <span className='k'>{t('SHIFT_FLAGS')}</span>
            <span
              className='v'
              style={{ display: attribs.length >= 2 ? 'block' : undefined }}
            >
              {attribs.map((att, aid) => (
                <ShiftAttribute key={att.id} attribute={att} short hideDeletion />
              ))}
            </span>
          </div>
        )
      }
    }

    // AVAIL OR TIMEOFF
    if (type === 'availability' || type === 'timeOff') {
      // work time
      if ((type === 'timeOff') && (typeof data.workMinutes !== typeof null) && (typeof data.workMinutes !== typeof undefined)) {
        topSectionRows.push(
          <div className='evt-tt-r-duration' key={topSectionRows.length}>
            <span className='k'>{t('ATTEN_DURATION')}</span>
            <span
              className='v'
            >{timeUtil.formatMinutes(data.workMinutes)}
            </span>
          </div>
        )
      }

      // category
      const cat = calendarUtil.getTimeOffOrAvailCategory(data, workspace)
      topSectionRows.push(
        <div key={topSectionRows.length}>
          <span className='k'>{t('EVT_TOOLTIP_CAT')}</span>
          <span className='v'>{cat.name}</span>
        </div>
      )

      // note
      if (data.note) {
        topSectionRows.push(
          <div className='evt-tt-r-note' key={topSectionRows.length}>
            <span className='k'>{t('NOTE')}</span>
            <span className='v'><div>{data.note}</div></span>
          </div>
        )
      }
    }

    // MULTIPLE EVENTS
    if (type === 'multipleEvents') {
      let countShifts = 0
      let countAvails = 0
      let countTimeOffs = 0

      if (Array.isArray(calendarMultiSelect?.sourceEvents)) {
        calendarMultiSelect.sourceEvents.forEach(evt => {
          const whatIsIt = (typeof evt.available === 'undefined' && !evt.categoryId)
            ? 'shift'
            : calendarUtil.isEventAvailabilityOrTimeOff(evt)
          if (whatIsIt === 'availability' || whatIsIt === 'unavailability') {
            countAvails += 1
          } else if (whatIsIt === 'timeOff') {
            countTimeOffs += 1
          } else {
            countShifts += 1
          }
        })
      }
      const selSummary = []
      if (countShifts > 0) selSummary.push(countShifts.toString() + ' ' + t('MULTI_SEL_SHIFTS_MULTI'))
      if (countTimeOffs > 0) selSummary.push(countTimeOffs.toString() + ' ' + t('MULTI_SEL_TIMEOFF_MULTI'))
      if (countAvails > 0) selSummary.push(countAvails.toString() + ' ' + t('MULTI_SEL_UNAV_MULTI'))

      topSectionRows.push(
        <div className='evt-tt-r-ms' key={topSectionRows.length}>
          <Text weight={Text.WEIGHTS.BOLD} color={Text.COLORS.PRIMARY} type={Text.TYPES.BODY_MEDIUM}>
            {t('MULTI_SELECTED') + ': '}
            {selSummary.join(', ')}
          </Text>
        </div>
      )
    }

    // =============================
    // CONTEXT MENU BUTTON HANDLERS:
    // =============================

    // add shift handler
    const handleAddShift = (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (isCycleCalendar) {
        const { cycleDetail } = this.props
        const dataFromId = data.id.split('_')
        const sortDetails = cycleDetail.data.sort(function (a, b) {
          return a.id > b.id ? 1 : -1
        })
        if (dataFromId.length === 3 && sortDetails.length) {
          const sortedId = sortDetails[0].data.id.split('_')[2]
          setModal('extra-shift',
            Object.assign({
              type: 'cycle',
              colId: parseInt(dataFromId[1]),
              groupId: parseInt(dataFromId[0]),
              eventId: parseInt(sortedId) + 1,
              afterSubmit: (p) => {
                if (p && p.data) setCycleDetailLastSaved(p.data)
              }
            },
            // if we have some object stored in store.cycleDetail.lastSavedCycleShift, send it to 'extra-shift' modal's props, so its values are used as defaults in the modal
            (cycleDetail.lastSavedCycleShift && cycleDetail.lastSavedCycleShift.period
              ? Object.assign({}, cycleDetail.lastSavedCycleShift, {
                hour: timeUtil.asMinutes(cycleDetail.lastSavedCycleShift.period.start) / 60,
                hourDuration: moment(cycleDetail.lastSavedCycleShift.period.end).diff(cycleDetail.lastSavedCycleShift.period.start, 'minutes') / 60,
                period: undefined
              })
              : {}))
          )
        }
      } else {
        setModal('extra-shift', {
          type: 'live',
          userId: data.userId,
          day: moment(data.period.start).format('YYYY-MM-DD')
        })
      }
      multiSelectUtil.multiSelectClear()
    }

    // edit handlers
    const handleEditShift = (e) => {
      e.preventDefault()
      e.stopPropagation()
      _openEventModal(data, 'shift', this.props)
      multiSelectUtil.multiSelectClear()
    }
    const handleEditUnav = (e) => {
      e.preventDefault()
      e.stopPropagation()
      const availOrTimeOff = calendarUtil.isEventAvailabilityOrTimeOff(data)

      _openEventModal(data, availOrTimeOff, this.props)
      multiSelectUtil.multiSelectClear()
    }
    const handleEtitShiftTemplate = (e) => {
      setModal('edit-shift-template', { shiftTemplateId: data.id })
      multiSelectUtil.multiSelectClear()
    }

    // unassign handler
    const handleUnassign = (e) => {
      e.preventDefault()
      e.stopPropagation()
      updateLiveShift(data.id, { userId: null })
    }

    // copy handler
    const handleCopy = (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.setState(s => Object.assign({}, s, { contextMenu: false }))
      setTimeout(() => {
        notification.success({ code: 'eventCopied' })
        setCalendarMultiSelect({
          action: 'copy',
          isSelectingTargets: true,
          sourceEvents: [Object.assign({}, data)],
          targets: []
        })
      }, 100) // we use setTimeout here, so that this is called *after* the Schedule component's dragSelect.callback() is resolved
    }

    // applyShiftTemplate handler
    const handleApplyShiftTemplate = (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.setState(s => Object.assign({}, s, { contextMenu: false }))
      setTimeout(() => {
        notification.success({ code: 'eventCopied' })
        setCalendarMultiSelect({
          action: 'applyShiftTemplate',
          isSelectingTargets: true,
          targets: [],
          isActive: true
        })
      }, 100) // we use setTimeout here, so that this is called *after* the Schedule component's dragSelect.callback() is resolved
    }

    // delete handler
    const handleDelete = (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (type === 'shiftTemplate') {
        updateShiftTemplate(data.id, null, null)
      } else {
        setModal('confirm', {
          title: t('MULTI_ACTION_DEL_CONFIRM_TITLE'),
          subtitle: t('MULTI_ACTION_DEL_CONFIRM_DELETE_SUBTITLE_X', { x: 1 }),
          recordsList: [data],
          confirmLabel: t('DELETE'),
          onConfirm: () => {
            if (type === 'shift' && !isCycleCalendar) {
              deleteLiveShift(data.id)
            }
            if (type === 'shift' && isCycleCalendar) {
              deleteCycleDetail({
                data: data
              })
            }
            if (type === 'availability') {
              requestAvailabilityDeleteMulti({
                data: [{ id: data.id }],
                workspace: workspace?.id
              }, auth).then(r => {
                if (!r?.error) notification.success({ code: 'success' })
              })
            }
            if (type === 'timeOff') {
              requestTimeOffDeleteMulti({
                data: [{ id: data.id }],
                workspace: workspace?.id
              }, auth).then(r => {
                if (!r?.error) notification.success({ code: 'success' })
              })
            }
            multiSelectUtil.multiSelectClear()
          },
          cancelLabel: t('CANCEL'),
          onCancel: () => { },
          overSidebar: true,
          buttonColor: 'red'
        })
      }
      multiSelectUtil.multiSelectClear()
    }

    // publish handler
    const handlePublish = (e) => {
      e.preventDefault()
      e.stopPropagation()
      ApolloClientFetch(auth, 'ShiftsPublishMutation')
        .mutate({
          mutation: gql`${ANNOUNCE_SHIFTS}`,
          variables: {
            workspaceId: workspace.id,
            ids: [data.id]
          }
        })
      multiSelectUtil.multiSelectClear()
    }

    // offer handler
    const handleOffer = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setModal('offers', {
        selectedShiftIds: [data.id],
        action: 'create',
        createType: 'drop'
      })
      multiSelectUtil.multiSelectClear()
    }

    // swap handler
    const handleSwap = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setModal('offers', {
        selectedShiftIds: [data.id],
        action: 'create',
        createType: 'swap'
      })
      multiSelectUtil.multiSelectClear()
    }

    // autoplan handler
    const handleAutoPlan = (e) => {
      e.preventDefault()
      e.stopPropagation()

      createPlan({
        type: 'selected',
        data: {
          selectedShifts: [data.id],
          period: { start: moment(data.period.start).startOf('day'), end: moment(data.period.end).endOf('day') }
          // nothing else is specified here, which makes the planner use defaults
        }
      })

      setSidebar('planning')
    }

    // add unav handler
    const handleAddUnav = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setModal('extra-unavailability', {
        newAvailabilityOrTimeOff: 'timeOff',
        userId: data.userId,
        day: moment(data.period.start).format('YYYY-MM-DD'),
        contractId: calendarMultiSelect.sourceEvents?.[0]?.contractId ?? null
      })
      multiSelectUtil.multiSelectClear()
    }

    // save as shift template handler
    const handleSaveAsShiftTemplate = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setModal('extra-shift-template', {
        hour: timeUtil.asMinutes(data.period.start) / 60,
        hourDuration: moment(data.period.end).diff(data.period.start, 'minutes') / 60,
        positionId: data.positionId,
        localityId: data.localityId,
        pauses: data.pausesFixed ? data.pauses : undefined,
        pausesFixed: data.pausesFixed,
        idealSkill: data.idealSkill,
        note: data.note,
        overTime: data.overTime,
        standBy: data.standBy,
        standByActivities: [],
        customAttributes: data.customAttributes,
        agenda: data.agenda
      })
      multiSelectUtil.multiSelectClear()
    }

    const menuItems = []
    // MENU ITEMS FOR MULTISELECTION
    if (calendarMultiSelect.sourceEvents.length >= 2) {
      const multiselectActions = multiSelectUtil.getMultiSelectAllowedActionTypes(isPluginEnabled) || []
      multiselectActions.forEach((act, i) => {
        let name = act.type
        let icon = null
        if (act.type === 'updateShiftTemplate') {
          name = t('MULTI_SHIFT_TEMPLATE_UPDATE')
          icon = Icon.ICONS.edit
        }
        if (act.type === 'applyShiftTemplate') {
          name = t('MULTI_SHIFT_TEMPLATE_APPLY')
          icon = Icon.ICONS.check
        }
        if (act.type === 'delete') {
          name = t('DELETE')
          icon = Icon.ICONS.delete
          if (menuItems.length !== 0) menuItems.push(<div className='is-divider' key={'divider_' + i} />)
        }
        if (act.type === 'switchTwoShifts') {
          name = t('MULTI_ACTION_SWAP_SHIFTS')
          icon = Icon.ICONS.switch
        }
        if (act.type === 'copy') {
          name = t('MULTI_ACTION_COPY_CONFIRM')
          icon = Icon.ICONS.copy
        }
        if (act.type === 'unassign') {
          name = t('MULTI_ACTION_UNASSIGN')
          icon = Icon.ICONS.unoccupied
        }
        if (act.type === 'viewOffers') {
          name = t('OFFERS')
          icon = Icon.ICONS.offer
        }
        if (act.type === 'cancelOffers') {
          name = t('MULTI_ACTION_UNASSIGN')
          icon = Icon.ICONS.close1
        }
        if (act.type === 'startDropOffers') {
          name = t('MULTI_ACTION_START_OFFERS')
          icon = Icon.ICONS.assign
        }
        if (act.type === 'startSwapOffers') {
          name = t('MULTI_ACTION_START_SWAP')
          icon = Icon.ICONS.switch
        }
        if (act.type === 'autoplan') {
          name = t('MULTI_ACTION_AUTOPLAN')
          icon = Icon.ICONS.items
        }
        if (act.type === 'batchUpdateShifts') {
          name = t('MULTI_ACTION_BATCH_UPDATE')
          icon = Icon.ICONS.edit
        }
        if (act.type === 'updateSingleEvent') {
          name = t('MULTI_ACTION_UPDATE_SINGLE_' + (calendarMultiSelect.sourceEvents[0].positionId ? 'SHIFT' : 'UNAV'))
          icon = Icon.ICONS.edit
        }
        if (act.type === 'deselectShifts') {
          name = t('MULTI_ACTION_DESELECT_SHIFTS')
          icon = Icon.ICONS.close1
        }
        if (act.type === 'deselectUnavs') {
          name = t('MULTI_ACTION_DESELECT_UNAVS')
          icon = Icon.ICONS.close1
        }
        if (act.type === 'deselectTimeOffs') {
          name = t('MULTI_ACTION_DESELECT_TIMEOFFS')
          icon = Icon.ICONS.close1
        }

        const handler = () => {
          multiSelectUtil.multiSelectExecuteAction(act.type)
        }
        menuItems.push(
          <div
            key={'ms_' + i}
            onMouseDown={handler}
            onTouchStart={handler}
            className={(act.type === 'delete') ? 'evt-tt-red-btn' : undefined}
          >
            {icon && (<Icon ico={icon} />)}
            {name}
          </div>)
      })
    }

    // MENU ITEMS FOR SINGLE SELECTION
    if (calendarMultiSelect.sourceEvents.length === 1) {
      if (type === 'shift' && !data.hasOffer) {
        // unassign
        if (data.userId) {
          menuItems.push(
            <div
              key='context_unass'
              onMouseDown={handleUnassign}
              onTouchStart={handleUnassign}
            >
              <Icon ico={Icon.ICONS.unoccupied} />
              {t('EVT_TOOLTIP_CM_UNASSIGN')}
            </div>)
        }

        if (isPluginEnabled('offers') && !isCycleCalendar && moment(data?.period.start).isAfter(moment().add(1, 'hour'))) {
          // offer
          menuItems.push(
            <div
              key='context_offer'
              onMouseDown={handleOffer}
              onTouchStart={handleOffer}
            >
              <Icon ico='assign' />
              {t('MULTI_ACTION_START_OFFERS')}
            </div>

          )

          // swap
          if (data.userId) {
            // swap
            menuItems.push(
              <div
                key='context_swap'
                onMouseDown={handleSwap}
                onTouchStart={handleSwap}
              >
                <Icon ico={Icon.ICONS.switch} />
                {t('MULTI_ACTION_START_SWAP')}
              </div>)
          }
        }

        // autoplan
        if (!data.userId) {
          menuItems.push(
            <div
              key='context_autoplan'
              onMouseDown={handleAutoPlan}
              onTouchStart={handleAutoPlan}
            >
              <Icon ico={Icon.ICONS.items} />
              {t('MULTI_ACTION_AUTOPLAN')}
            </div>)
        }

        // add unav
        if (data.userId && !isCycleCalendar) {
          menuItems.push(
            <div
              key='context_add_unav'
              onMouseDown={handleAddUnav}
              onTouchStart={handleAddUnav}
            >
              <Icon ico={Icon.ICONS.absence} />
              {t('COL_TOOLTIP_CM_ADD_ABSENCE')}
            </div>)
        }
      }

      // divider
      if (menuItems.length !== 0) menuItems.push(<div className='is-divider' key='divider_before_add_shift' />)

      // add shift
      if (type !== 'shiftTemplate') {
        menuItems.push(
          <div
            key='context_add_shift'
            onMouseDown={handleAddShift}
            onTouchStart={handleAddShift}
          >
            <Icon ico='plus' />
            {t('EVT_TOOLTIP_CM_ADD_SHIFT')}
          </div>)
      }

      // edit
      menuItems.push(
        <div
          key='context_edit'
          onMouseDown={type === 'shift'
            ? handleEditShift
            : type === 'shiftTemplate'
              ? handleEtitShiftTemplate
              : handleEditUnav}
          onTouchStart={type === 'shift'
            ? handleEditShift
            : type === 'shiftTemplate'
              ? handleEtitShiftTemplate
              : handleEditUnav}
        >
          <Icon ico='edit' />
          {t('EVT_TOOLTIP_CM_EDIT')}
        </div>)

      // copy
      if (!hasMaster && !isCycleCalendar & type !== 'shiftTemplate') {
        menuItems.push(
          <div
            key='context_copy'
            onMouseDown={handleCopy}
            onTouchStart={handleCopy}
          >
            <Icon ico={Icon.ICONS.duplicate} />
            {t('COPY')}
          </div>)
      }

      // apply shift template
      if (type === 'shiftTemplate') {
        menuItems.push(
          <div
            key='context_apply_template'
            onMouseDown={handleApplyShiftTemplate}
            onTouchStart={handleApplyShiftTemplate}
          >
            <Icon ico={Icon.ICONS.duplicate} />
            {t('COPY')}
          </div>)
      }

      // publish shift
      if (type === 'shift' && !data.published && !isCycleCalendar) {
        menuItems.push(
          <div
            key='context_pub'
            onMouseDown={handlePublish}
            onTouchStart={handlePublish}
          >
            <Icon ico='publish' />
            {t('EVT_TOOLTIP_CM_PUBLISH')}
          </div>)
      }

      // save as template
      if (type === 'shift' && !isCycleCalendar) {
        if (menuItems.length !== 0) menuItems.push(<div className='is-divider' key='divider_before_save_template' />) // divider
        menuItems.push(
          <div
            key='context_save_template'
            onMouseDown={handleSaveAsShiftTemplate}
            onTouchStart={handleSaveAsShiftTemplate}
          >
            <Icon ico='save' />
            {t('EVT_TOOLTIP_SAVE_AS_SHIFT_TPL')}
          </div>
        )
      }

      // delete
      if (!hasMaster) {
        if (menuItems.length !== 0) menuItems.push(<div className='is-divider' key='divider_before_del' />) // divider
        menuItems.push(
          <div
            key='context_del'
            onMouseDown={handleDelete}
            onTouchStart={handleDelete}
            className='evt-tt-red-btn'
          >
            <Icon ico={Icon.ICONS.delete} />
            {t('DELETE')}
          </div>)
      }
    }

    return (
      <>
        {topSectionRows}
        {this.state.contextMenu && !isLockedDay && (
          <div className='evt-tt-cm'>
            {menuItems}
          </div>
        )}
      </>
    )
  }

  render () {
    const {
      type,
      data,
      isEmployeeCalendar,
      calendarMultiSelect,
      rowEmployee,
      isFirstShiftInDay,
      isCyclePreview,
      isCycleCalendar,
      cycleCalendar
    } = this.props
    const { view } = isCycleCalendar ? cycleCalendar : this.props.calendar

    let customStyles = {}
    let marginLeft = null
    let width = null
    if (view === 'day' || isEmployeeCalendar) {
      marginLeft = (moment(data.period.start).minute() / 60 * 100).toString() + '%'
      const widthPercent = (moment(data.period.end).diff(data.period.start) / 60000 / 60 * 100)
      width = 'calc(' + widthPercent.toString() + '% + ' + (widthPercent / 100).toString() + 'px)'
    }
    if (marginLeft && width) {
      customStyles = {
        marginLeft,
        width,
        minWidth: width,
        maxWidth: width,
        zIndex: 2
      }
    }
    const thisIsTopSectionRow = ['offers', 'unassigned'].includes(rowEmployee)

    if (typeof data.verticalShift !== typeof undefined) {
      // This sets, now, unnecessary margin-top on event in unassigned section day view
      customStyles.marginTop = (data.verticalShift * 3.1875).toString() + 'rem' // note: row height is set to 3.1875rem in CSS

      customStyles.position = 'absolute'
    }

    const isMultiSelectEnabled = document.querySelector('.ds-ms-selector') || (!window.matchMedia('only screen and (max-device-width: 699px)').matches)
    const isEventMultiSelected = isMultiSelectEnabled && calendarMultiSelect.isActive && calendarMultiSelect.sourceEvents && calendarMultiSelect.sourceEvents.find(ev => ev.id === data.id)
    const isEvtMSSingle = isEventMultiSelected &&
      calendarMultiSelect.sourceEvents.length === 1 &&
      !calendarMultiSelect.isSelectingTargets

    // render the event element
    let ttAnchor = null
    if (type === 'shift') {
      ttAnchor = this.getShiftHTML(data, {
        view,
        customStyles,
        isMultiSelectEnabled,
        isEventMultiSelected,
        isEvtMSSingle,
        rowEmployee,
        isFirstShiftInDay
      })
    }
    if (type === 'availability' || type === 'timeOff') {
      ttAnchor = this.getAvailOrTimeOffHTML(data, {
        view,
        customStyles,
        isMultiSelectEnabled,
        isEventMultiSelected,
        isEvtMSSingle
      })
    }
    if (type === 'shiftTemplate') {
      ttAnchor = this.getShiftTemplateHTML(data, {})
    }

    // prepare the event tooltip if needed
    let tooltipHTML = null
    if ((this.state.contextMenu || isEvtMSSingle) && !this.state.isBeingDragged) {
      tooltipHTML = (
        <Tooltip
          clickable
          openOnMount
          anchor={ttAnchor}
          width='11.875rem'
          closeCallback={() => {
            // when the tooltip is closed, set the window.dsMsTooltipWasRecentlyClosed flag to current timestamp.
            // it's used to prevent opening the editation modals when clicked on hoverbtns immediatelly after the event tooltip is closed.
            window.dsMsTooltipWasRecentlyClosed = Date.now()
          }}
        >
          <div className={'evt-tt evt-tt-' + data.id}>
            {this.getTooltipContent(data, isEvtMSSingle ? type : 'multipleEvents')}
          </div>
        </Tooltip>
      )

      // if this is the 1st shift in the column and we're in bottom section,
      // make sure it's positioned at the bottom of the column
      if (isFirstShiftInDay && !thisIsTopSectionRow) {
        tooltipHTML = (
          // <div style={{ display: 'contents' }}>
          // display: contents for some reason moves the event below its own position
          <div>
            <div style={{ marginTop: 'auto' }} />
            {tooltipHTML}
          </div>
        )
      }
    }

    if (isCyclePreview && isCycleCalendar) {
      return ttAnchor
    }
    return tooltipHTML || ttAnchor
  }
}

export const openEventModal = _openEventModal
export default connect(Event)
