import moment from 'moment'
import ReactDOMServer from 'react-dom/server'
import { Fragment } from 'react'
import store from '@app/store'
import { t } from 'i18next'
import {
  setEnforcedLocality,
  isLoading,
  setModal
} from '@app/ac/'
import {
  notification,
  sortUtil,
  numberUtil,
  fetchRequest,
  permissionsUtil,
  isNotNull,
  isDefined,
  isUndefined,
  isNull
} from '@app/util/'
import {
  Tooltip,
  Spacing,
  Alert
} from '@ui'
import { NO_LOCALITY } from '@app/const/globals'
import { PERMISSIONS } from '@app/const'

/**
* @param {string | undefined | null} phoneNumber
* @returns {string}
*/
const formatPhoneNumber = (phoneNumber) => {
  if (isUndefined(phoneNumber) || isNull(phoneNumber)) return undefined
  const [ą, b] = phoneNumber.split('-')

  const code = isDefined(b) ? `+${ą}` : ''

  const number = b?.split('').reduce((acc, char, index) => {
    if (index % 3 === 0) return `${acc} ${char}`
    return acc + char
  }, '')

  const result = isDefined(b) ? `${code} ${number}` : number

  return result
}

function _isObject (item) {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

const _safeDateToStr = (obj) => {
  const m = moment(obj)
  if (m && m.isValid()) {
    return m.format()
  } else if (obj && obj.toUTCString) {
    return obj.toUTCString()
  } else {
    return obj.toString()
  }
}

const _getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (value instanceof Date) {
      value = _safeDateToStr(value)
    }
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

function safeStringify (obj) {
  // Sometimes, for whatever reason, Date objects don't have toISOString() method, that's
  // called on them by JSON.stringify. To prevent exception, we treat Date objects separately.
  if (obj instanceof Date) {
    return _safeDateToStr(obj)
  } else {
    return JSON.stringify(obj, _getCircularReplacer(), 2)
  }
}

// confirmed name strings that can be returned by this: Firefox, Chrome, Edge
function getBrowser () {
  var ua = navigator.userAgent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: (tem && tem.length > 1 ? tem[1] : 0) }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if (tem != null) { return { name: 'Edge', version: (tem && tem.length > 1 ? tem[1] : 0) } }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, (tem && tem.length > 1 ? tem[1] : 0)) }
  return {
    name: M[0],
    version: M[1]
  }
}

// deep merge two objects.
function mergeDeep (target, source) {
  const output = Object.assign({}, target)
  if (_isObject(target) && _isObject(source)) {
    Object.keys(source).forEach(key => {
      if (_isObject(source[key])) {
        if (!(key in target)) { Object.assign(output, { [key]: source[key] }) } else { output[key] = mergeDeep(target[key], source[key]) }
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

function hasEventChanged (oldEvent, newEvent) {
  if (oldEvent.type === 'shift') {
    if (oldEvent.data.id !== newEvent.data.id) {
      return true
    }
    if (oldEvent.data.published !== newEvent.data.published) {
      return true
    }
    if (oldEvent.data.positionId !== newEvent.data.positionId) {
      return true
    }
    if (oldEvent.data.localityId !== newEvent.data.localityId) {
      return true
    }
    if (oldEvent.data.duration !== newEvent.data.duration) {
      return true
    }
    if (safeStringify(oldEvent.data.pauses) !== safeStringify(newEvent.data.pauses)) {
      return true
    }
    if (oldEvent.data.warnings !== newEvent.data.warnings) {
      return true
    }
    if (oldEvent.data.warnings && newEvent.data.warnings && oldEvent.data.warnings.length !== newEvent.data.warnings.length) {
      return true
    }
    if (oldEvent.data.plannedByPlanner !== newEvent.data.plannedByPlanner) {
      return true
    }
    if (oldEvent.data.hasOffer !== newEvent.data.hasOffer) {
      return true
    }
    if (oldEvent.data.standBy !== newEvent.data.standBy) {
      return true
    }
    if (safeStringify(oldEvent.data.standByActivities) !== safeStringify(newEvent.data.standByActivities)) {
      return true
    }
    if (safeStringify(oldEvent.data.overTime) !== safeStringify(newEvent.data.overTime)) {
      return true
    }
    if (safeStringify(oldEvent.data.customAttributes) !== safeStringify(newEvent.data.customAttributes)) {
      return true
    }
    if (oldEvent.data.note !== newEvent.data.note) {
      return true
    }
  }

  if (oldEvent.type === 'timeOff') {
    if (oldEvent.data?.categoryId !== newEvent.data?.categoryId) {
      return true
    }
    if (oldEvent.data?.workMinutes !== newEvent.data?.workMinutes) {
      return true
    }
    if (oldEvent.data?.requestPendingAction !== newEvent.data?.requestPendingAction) {
      return true
    }
    if (oldEvent.data.warnings !== newEvent.data.warnings) {
      return true
    }
  }

  if (oldEvent.type === 'shiftTemplate') {
    if (oldEvent.data?.shiftTemplateName !== newEvent.data?.shiftTemplateName) return true
  }

  // userId changed
  if (oldEvent.data.userId !== newEvent.data.userId) {
    return true
  }

  // period changed
  if (oldEvent.data.period.start !== newEvent.data.period.start || oldEvent.data.period.end !== newEvent.data.period.end) {
    return true
  }
  return false
}

function getEmployeeActivity (ee) {
  // warning type represent colors in Text component
  const okStr = t('ACTIVATED')
  let text = okStr
  let warningType = 'success'
  let lastActivity = null

  if (ee.invited) {
    text = t('INVITED')
    warningType = 'default'
  } else if (ee.employeeWarnings &&
    ee.employeeWarnings.length &&
    (ee.employeeWarnings.length > 1 || ee.employeeWarnings[0].type !== 'dummy')
  ) {
    text = t('EMPL_WARNING')
    warningType = 'danger'
  } else if (ee.dummy) {
    text = t('EMPL_INCOMPLETE')
    warningType = 'warning'
  }
  if (ee.lastSeen && text === okStr) {
    const actStr = moment(ee.lastSeen).fromNow()
    lastActivity = (actStr && actStr.charAt(0)) ? actStr.charAt(0).toUpperCase() + actStr.slice(1) : null
  }
  return { text, warningType, lastActivity }
}

// 2 functions for exporting any table as CSV from https://www.codexworld.com/export-html-table-data-to-csv-using-javascript/
function _downloadCSV (csv, filename) {
  var csvFile
  var downloadLink

  /* eslint-disable-next-line */
  csvFile = new Blob(["\ufeff", csv], { type: 'text/csv' })
  downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}
function downloadTableAsCSV (tableElement, filename) {
  if (!tableElement) return
  var csv = []
  var rows = tableElement.querySelectorAll('tr')
  if (!rows || !rows.length) return
  for (var i = 0; i < rows.length; i++) {
    var row = []; var cols = rows[i].querySelectorAll('td, th')
    for (var j = 0; j < cols.length; j++) { row.push(cols[j].innerText) }
    csv.push(row.join(','))
  }
  _downloadCSV(csv.join('\n'), filename)
}

const getNodeText = (node) => {
  if (['string', 'number'].includes(typeof node)) return node
  if (node instanceof Array) return node.map(getNodeText).join(' ').trim()
  if (typeof node === 'object' && node) {
    if (node.props.children) {
      return getNodeText(node.props.children).trim()
    } else {
      try {
        return ReactDOMServer.renderToStaticMarkup(node).replace(/<\/?[^>]+(>|$)/g, '').trim()
      } catch (e) {
        return ''
      }
    }
  }
  return ''
}

const normalizeString = (value) => {
  const stringified = typeof value === 'number' || typeof value === 'boolean'
    ? value.toString()
    : typeof value === 'object'
      ? getNodeText(value)
      : (value || '')

  return stringified && stringified
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

const isDayswapsProInterface = () => {
  return Boolean(window.location.pathname.includes('/organization'))
}

const isTerminalInterface = () => {
  return Boolean(window.location.pathname.includes('/t/'))
}

// assuming that logged in user's selected workspace has enforcedLocalities == true,
// return the ID of selected locality that should be loaded and displayed.
// otherwise, return null.
const getEnforcedLocality = (wsOverride) => {
  const { me, workspaceId, workspaces } = store.getState()
  if (!me.id) return null

  const workspace = wsOverride || workspaces.find(w => w.id === workspaceId)

  // if workspace has enforcedLocalities set to true ...
  if (workspace && workspace.enforceLocalities && workspace.localities) {
    const { enforcedLocality } = store.getState()

    // if store.enforcedLocality is set to NO_LOCALITY constant, just return it
    if (enforcedLocality === NO_LOCALITY) {
      return enforcedLocality
    }

    // if store.enforcedLocality is set and it exists on current WS and I have access to it, just return it
    const myLocalities = workspace.localities.filter(l => l.assigns && l.assigns.some(ass => ass.userId === me.id))
    if (enforcedLocality && workspace.localities.some(l => l.id === enforcedLocality) && (myLocalities.length === 0 || myLocalities?.some(ml => ml.id === enforcedLocality))) {
      return enforcedLocality
    }

    // if I have exactly one locality, return that one and store it in store.enforcedLocality
    if (myLocalities.length === 1) {
      setEnforcedLocality(myLocalities[0].id)
      return myLocalities[0].id
    }

    // if I have 0 or more than 1 locality, and none of them is in store.enforcedLocality, save the 1st available locality from WS in store.enforcedLocality and return it
    if (myLocalities.length !== 1 && workspace.localities?.length) {
      setEnforcedLocality(workspace.localities[0].id)
      return workspace.localities[0].id
    }
  }

  // by default, return null
  return null
}

// return the list of all my organization permissions on a selected organization
const getMyOrgPermissions = () => {
  const { me, organizationId } = store.getState()
  if (!me.id) return null
  if (Object.keys(me?.acl?.organizations).includes(organizationId)) return me?.acl?.organizations[organizationId]
  return []
}

// return the list of all my country permissions on a given country
const getMyCountryPermissions = (countryId) => {
  const { me } = store.getState()
  if (!me.id) return null
  if (Object.keys(me?.acl?.countries).includes(countryId)) return me?.acl?.countries[countryId]
  return []
}

// return true if the calendar is locked on 'referenceMoment' by:
// a) lack of write permission for manager's calendar
// b) 'Workspace.calendarLockedUntil' set to later date, unless that lock is bypassed by some of my permissions (payroll_attendance*)
const isMyCalendarLocked = (referenceMoment, workspace) => {
  // a) locked by missing write permission for manager's calendar
  if (!permissionsUtil.canWrite(PERMISSIONS.CALENDAR) && window.location.pathname.includes('/schedule')) {
    return true
  }

  // b) if calendarLock is set to a moment after the 'referenceMoment' ...
  if (moment(workspace?.calendarLockedUntil).isValid() && moment(referenceMoment).isValid() && moment(workspace.calendarLockedUntil).isAfter(referenceMoment)) {
    // check if the lock is bypassed by some of my organization permissions
    let bypassed = false

    const { me } = store.getState()
    const myWsPermissions = Object.keys(me?.acl?.workspaces).includes(workspace?.id) ? me?.acl?.workspaces[workspace?.id] : []

    // 'payroll_attendance_lvl3' permission bypasses the lock unless 'calendarApprovedUntilLvl3' is set after the 'referenceMoment'
    if (!bypassed && myWsPermissions.includes('payroll_attendance_lvl3') && (!moment(workspace?.calendarApprovedUntilLvl3).isValid() || moment(workspace?.calendarApprovedUntilLvl3).isSameOrBefore(referenceMoment))) bypassed = true
    // 'payroll_attendance_lvl2' permission bypasses the lock unless 'calendarApprovedUntilLvl2' is set after the 'referenceMoment'
    if (!bypassed && myWsPermissions.includes('payroll_attendance_lvl2') && (!moment(workspace?.calendarApprovedUntilLvl2).isValid() || moment(workspace?.calendarApprovedUntilLvl2).isSameOrBefore(referenceMoment))) bypassed = true
    // 'payroll_attendance' permission bypasses the lock unless 'calendarApprovedUntil' is set after the 'referenceMoment'
    if (!bypassed && myWsPermissions.includes('payroll_attendance') && (!moment(workspace?.calendarApprovedUntil).isValid() || moment(workspace?.calendarApprovedUntil).isSameOrBefore(referenceMoment))) bypassed = true

    // if the lock is not bypassed by abything, return true
    if (!bypassed) return true
  }
  return false
}

// returns a list of positions that an employee or role has assigned at a given time (looks at the role's contracts)
// Note: the 'eeOrRole' needs to have 'contracts' prop, which means it's either a role or an employee object from store.employees
const getPositionsOnRole = (eeOrRole, when) => {
  const activeContracts = (typeof when === 'object' && when?.start && when?.end)
    // if 'when' is period
    ? (eeOrRole?.contracts || []).filter(c => !((c.period?.start && moment(c.period.start).isAfter(when.end)) || (c.period?.end && moment(c.period.end).isBefore(when.start))))
    // if 'when' is Moment or string
    : (eeOrRole?.contracts || []).filter(c => !((c.period?.start && moment(c.period.start).isAfter(when)) || (c.period?.end && moment(c.period.end).isBefore(when))))
  if (!activeContracts.length) return []

  const { positions } = store.getState()
  const sortedPositions = sortUtil.sortByKey(positions.filter(p => !p.archived), 'name')

  return sortedPositions.map((p) => {
    const ac = activeContracts.find(c => c.positions?.some(cp => cp.positionId === p.id))
    if (!ac) return null
    const ass = ac.positions.find(cp => cp.positionId === p.id)
    return {
      id: p.id,
      name: p.name,
      color: p.color,
      productionResourceType: p.productionResourceType,
      purpose: p.purpose,
      skill: ass.skill,
      productionForecast: ass.productionForecast
    }
  }).filter(Boolean)
}

// given User API object in 'u' and Role API object in 'r', returns
// an object that represents a given employee in our Redux store
const getEmployeeObjectForStore = (u, r) => {
  const { workspaces, workspaceId } = store.getState()
  const ws = workspaces.find(w => w.id === workspaceId)

  const localities = ws ? ws.localities : []
  const filPos = getPositionsOnRole(r, moment())

  const filLoc = localities ? localities.filter((l) => {
    return !!l.assigns.find((a) => a.userId === u.id)
  }).map(l => l.id) : []

  let invite = null
  if (ws && ws.invites) {
    let relevantInvs = ws.invites.filter(inv => !inv.resolved && inv.mergeWith === u.id)
    if (relevantInvs.length) {
      // select the newest unresolved invite of this user
      relevantInvs = relevantInvs.sort((a, b) => {
        return (a.created < b.created ? 1 : -1)
      })
      invite = relevantInvs[0]
    }
  }

  // workspace-specific, manager-given name in Role.customData can override the original user's name
  let customName = null
  if (r.customData && r.customData.lastName && r.customData.firstName) {
    customName = [
      (r.customData.lastName || u.lastName),
      (r.customData.firstName || u.firstName)
    ].filter(s => !!s).join(' ')
  }

  const ret = {
    id: u.id,
    email: u.email,
    firstName: (r.customData.firstName || u.firstName),
    lastName: (r.customData.lastName || u.lastName),
    telephone: (r.customData && r.customData.telephone) ? r.customData.telephone : u.telephone,
    name: customName || u.name,
    employeeId: r.customData ? r.customData.employeeId : null,
    dummy: !!u.dummy,
    invited: invite ? invite.id : false,
    positions: filPos,
    localities: filLoc,
    role: r.role,
    terminalPIN: r.terminalPIN,
    cycleId: r.cycleId,
    cycleGroup: r.cycleGroup,
    terminateDate: r.terminateDate,
    group: r.group,
    agreement: ((r?.contract?.compiled?.options) ? (numberUtil.round2decimals(r.contract.compiled.options.minutesOfWorkPerMonth / 60, false)) : 0), // hours per month
    agreementPerShift: ((r?.contract?.compiled?.options) ? (numberUtil.round2decimals(r.contract.compiled.options.minutesOfWorkPerShift / 60, false)) : 0), // hours per shift
    contractType: r.contract ? r.contract.type : null,
    contractId: r.contract ? r.contract.id : null,
    contractPeriod: r.contract ? r.contract.period : null,
    contracts: r.contracts ? r.contracts : [],
    adult: r.contract && r.contract.compiled && r.contract.compiled.options ? r.contract.compiled.options.adult : true, // use contract's adult setting if it's there. otherwise, default to 'true'
    lastSeen: u.lastActivity,
    lastMobileAppUse: u.lastMobileAppUse,
    calendarOrder: r.calendarOrder,
    mfa: u.mfa
  }

  return ret
}

const copyToClipBoard = (text) => {
  let isError = false
  // use Clipboard API if available
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Unable to write text to clipboard using Clipboard API', err)
      isError = true
    })
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    // fallback to document.execCommand('copy') method for older browsers
    var textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // prevent scrolling to bottom of page
    document.body.appendChild(textarea)
    textarea.select()
    try {
      document.execCommand('copy') // copy text to clipboard
    } catch (err) {
      console.error('Unable to write text to clipboard using document.execCommand', err)
      isError = true
    } finally {
      document.body.removeChild(textarea)
    }
  } else {
    console.error('Clipboard functionality is not available in this browser')
    isError = true
  }

  if (!isError) notification.success({ code: 'copied' })
}

const downloadFile = (url) => {
  const win = window.open(url, '_blank')
  win.focus()
}

const openStripeUI = async () => {
  const { auth, organizationId } = store.getState()
  const dispatch = store.dispatch

  await dispatch(isLoading('load-stripe-url'))
  const res = await fetchRequest({
    query: `query OrganizationStripeLink($organizationId: ID!) {
    organization(id: $organizationId) {
      stripeCustomerPortalLink
    }
  }`,
    variables: { organizationId },
    token: auth
  })
  await dispatch(isLoading('load-stripe-url', true))

  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
  if (urlRegex.test(res?.data?.organization?.stripeCustomerPortalLink)) {
    window.open(res?.data?.organization?.stripeCustomerPortalLink, '_blank')
  } else {
    if (res?.errors?.length) notification.error({ message: res.errors[0].message })
  }
}

// get merged periods of the array of events
const getMergedEventPeriods = (events) => {
  if (!Array.isArray(events) || !events?.length) return []
  // 1) sort the array based on the 'start' of each period
  const sortedEventPeriods = events.map(evt => {
    return {
      start: moment(evt.period.start),
      end: moment(evt.period.end)
    }
  })
  sortedEventPeriods.sort((a, b) => {
    if (a.start.isBefore(b.start)) return -1
    if (a.start.isAfter(b.start)) return 1
    return 0
  })
  // 2) iterate through the sorted array and merge periods that overlap
  const mergedResultPeriods = []
  let currentPeriod = sortedEventPeriods[0]

  for (let i = 1; i < sortedEventPeriods.length; i++) {
    const nextPeriod = sortedEventPeriods[i]
    if (currentPeriod.end.isSameOrAfter(nextPeriod.start)) { // if the 'end' of the current period is later than or equal to the 'start' of the next period, they overlap
      currentPeriod.end = moment.max(currentPeriod.end, nextPeriod.end) // update the 'end' of the current period to be the later of the two 'end' times
    } else {
      mergedResultPeriods.push(currentPeriod) // if they don't overlap, push the current period to the merged array
      currentPeriod = nextPeriod // and move to the next period
    }
  }
  mergedResultPeriods.push(currentPeriod)
  return mergedResultPeriods
}

// get merged periods of the array of events (same as above, but for events with 'start' and 'duration' instead of 'period')
function getMergedEventsWithStartsAndDurations (events) {
  const sortedEvents = [...events].sort((a, b) => a.start - b.start)
  const mergedEvents = []
  sortedEvents.forEach(event => {
    if (!mergedEvents.length || mergedEvents[mergedEvents.length - 1].start + mergedEvents[mergedEvents.length - 1].duration <= event.start) {
      // no overlap
      mergedEvents.push({ ...event })
    } else {
      // overlap, merge the events
      const lastEvent = mergedEvents[mergedEvents.length - 1]
      const newEnd = Math.max(lastEvent.start + lastEvent.duration, event.start + event.duration)
      lastEvent.duration = newEnd - lastEvent.start
    }
  })
  return mergedEvents
}

function getEventWarningsForEditModal (details, isLaborLawPluginEnabled) {
  const { calendarFilters } = store.getState()
  const dispatch = store.dispatch

  const warnings = (details.warnings && details.warnings.length) ? details.warnings : []

  // exclude currently hidden warnings
  const warningsFilter = calendarFilters.find(fil => fil.hideWarnings) || { hideWarnings: [] }
  const hiddenWarningsArray = warningsFilter.hideWarnings
  const warningsStrippedOfHidden = warnings.filter(w => !hiddenWarningsArray || !hiddenWarningsArray.includes(w.name))
  const anyWarningsWithoutHidden = !!(warnings.filter(w => !hiddenWarningsArray || !hiddenWarningsArray.includes(w.name)).length > 0) && (hiddenWarningsArray !== 'all')

  return ((details.positionId || details.categoryId) && warnings.length > 0 && anyWarningsWithoutHidden && hiddenWarningsArray !== 'all'
    ? (
      <Fragment key='modal-section-warns'>
        {/* Warnings */}

        {isLaborLawPluginEnabled
          ? (
            <div>
              {warningsStrippedOfHidden.map((w, i) => w.data ? (
                <div
                  key={'warn_' + i.toString()} style={{
                    width: 'calc(50% - 0.375rem)',
                    marginRight: '0.375rem',
                    marginBottom: '0.375rem',
                    display: 'inline-block'
                  }}
                >
                  <Tooltip
                    anchor={
                      <Alert
                        text={t('WARNING_' + w.name)} type={Alert.TYPES.ERROR}
                        size={Alert.SIZES.FULL_WIDTH}
                      />
                    }
                  >
                    <Spacing
                      type={Spacing.TYPES.BOTH}
                      size={Spacing.SIZES.SIZE_8}
                    >{t(w.messageKey || (`WARNINGS_EXPLANATION.${w.name}`), w.data)}
                    </Spacing>
                  </Tooltip>
                </div>
              ) : (
                <div
                  key={'warn_' + i.toString()} style={{
                    width: 'calc(50% - 0.375rem)',
                    marginRight: '0.375rem',
                    marginBottom: '0.375rem',
                    display: 'inline-block'
                  }}
                >
                  <Alert
                    text={t('WARNING_' + w.name)} type={Alert.TYPES.ERROR}
                    size={Alert.SIZES.FULL_WIDTH}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              <Alert text={t('WARNING_lawModuleOff')} type={Alert.TYPES.ERROR} size={Alert.SIZES.FULL_WIDTH} />
              <div className='ds-shiftinfo-lawmodule-desc-row'>
                <div>{t('WARNINGS_EXPLANATION.lawModuleOff1')}</div>
                <div
                  className='ds-link'
                  onClick={async () => {
                    await dispatch(setModal('plugin-detail', { plugin: { plugin: 'laborlaw' } }))
                  }}
                >
                  {t('WARNINGS_EXPLANATION.lawModuleOff2')}
                </div>
                <div>{t('WARNINGS_EXPLANATION.lawModuleOff3')}</div>
              </div>
            </>)}

      </Fragment>
    ) : null)
}

// Custom content replacement for arbitrary content for specific organizations.
// Use this when an org has some special needs like "don't display dropdown option X".
function getFilteredContentForOrganization (contentType, normalContent) {
  const { organizationId } = store.getState()
  const organizationContentFilters = {
    // Sportisimo
    AofOCI1v4: {
      timeoff_conflict_dropdown_option3: null, // FORMAT: this means that content with "timeoff_conflict_dropdown_option3" type will be replaced by "null" for organization "AofOCI1v4"
      timeoff_conflict_dropdown_option4: null,
      timeoff_conflict_dropdown_option5: null,
      agreement_name: t('SPORTISIMO_AGREEMENT'),
      time_off_edit_include_hour_option_contract: null,
      time_off_edit_include_hour_option_settings: null,
      employee_card_hide_emp: null
    },
    // Tchibo (CZ)
    YC_stvpc9B: {
      shift_overtime_unpaid_toil: null,
      agreement_name: t('SPORTISIMO_AGREEMENT')
    },
    // Tchibo Slovensko (SK)
    VXWHAT_GqL: {
      shift_overtime_unpaid_toil: null,
      agreement_name: t('SPORTISIMO_AGREEMENT')
    },
    // Moravia Cans
    acFVAdebWUU6: {
      timeoff_conflict_dropdown_option1: null,
      timeoff_conflict_dropdown_option2: null,
      timeoff_conflict_dropdown_option3: null,
      timeoff_conflict_dropdown_option4: null
      // only timeoff_conflict_dropdown_option5 should be visible

    },
    // Teleperformance
    '7RnVL2ZDE7': {
      // only timeoff_conflict_dropdown_option1 should be visible
      timeoff_conflict_dropdown_option4: null,
      timeoff_conflict_dropdown_option5: null
    }
  }

  if (organizationId && Object.keys(organizationContentFilters).includes(organizationId) && Object.keys(organizationContentFilters[organizationId]).includes(contentType)) {
    return organizationContentFilters[organizationId][contentType]
  } else {
    return normalContent
  }
}

export const getOrganizationPluginActionException = (actionType) => {
  const { organizationId } = store.getState()

  // True if the action should be allowed, false if it should be blocked
  const organizationPluginActionExceptions = {
    // Tchibo Slovensko (SK)
    VXWHAT_GqL: {
      addEmployee: true
    },
    // Tchibo
    YC_stvpc9B: {
      addEmployee: true
    }
  }

  if (organizationId && Object.keys(organizationPluginActionExceptions).includes(organizationId) && Object.keys(organizationPluginActionExceptions[organizationId]).includes(actionType)) {
    return organizationPluginActionExceptions[organizationId][actionType]
  }
}

export const logicalOverride = (override, normal) => isNotNull(override) || isDefined(override) ? override : normal

export const getFilteredContentForCountry = (contentType, normalContent) => {
  const { workspaces, workspaceId } = store.getState()
  const ws = workspaces.find(w => w.id === workspaceId)
  const country = ws.country
  const countryFilters = {
    HU: {
      shift_overtime_unpaid_action_dropdown: null,
      shift_overtime_unpaid_type_dropdown: null,

      // Due to how bonuses page works, these exceptions are hard-coded in BonusesTableConfig
      // but I kept them here for reference
      travel_public: true,
      travel_public_modification: true,
      travel_km: true,
      travel_km_modification: true
    }
  }

  if (country && Object.keys(countryFilters).includes(country) && Object.keys(countryFilters[country]).includes(contentType)) {
    return countryFilters[country][contentType]
  } else {
    return normalContent
  }
}

export const getPhoneNumberRegexForCountry = () => {
  const { workspaces, workspaceId } = store.getState()
  const ws = workspaces.find(w => w.id === workspaceId)
  const country = ws.country

  const countryRegexes = {
    CZ: /^(?:(?:\+|0{2})?[1-9][0-9]{2})?(?: ?|-?)[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/,
    SK: /^(?:(?:\+|0{2})?[1-9][0-9]{2})?(?: ?|-?)[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/,
    HU: /(?:(?:\+|0{2})?(?:3|0)6)? ?[0-9]{2}(?: ?|-?)[0-9]{3} ?[0-9]{3}/
  }

  if (country && Object.keys(countryRegexes).includes(country) && Object.keys(countryRegexes[country])) {
    return countryRegexes[country]
  } else {
    return new RegExp()
  }
}

export default {
  safeStringify,
  getFilteredContentForOrganization,
  getEventWarningsForEditModal,
  getBrowser,
  getNodeText,
  mergeDeep,
  hasEventChanged,
  getEmployeeActivity,
  downloadTableAsCSV,
  normalizeString,
  getEnforcedLocality,
  getMyOrgPermissions,
  getMyCountryPermissions,
  getEmployeeObjectForStore,
  getMergedEventPeriods,
  getMergedEventsWithStartsAndDurations,
  isDayswapsProInterface,
  isTerminalInterface,
  isMyCalendarLocked,
  copyToClipBoard,
  downloadFile,
  openStripeUI,
  getPositionsOnRole,
  formatPhoneNumber
}
