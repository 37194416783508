import React, { Fragment, useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

import {
  Button,
  Modal,
  Alert,
  Text,
  Flex,
  Spacing,
  RadioGroup
} from '@ui'

import RecordsList from './records-list'
import './index.scss'

const Confirm = ({
  title,
  subtitle,
  text,
  type,
  recordsList,
  buttonColor,
  confirmLabel,
  cancelLabel,
  isTallModal,
  onConfirm,
  onCancel,
  onConfirmBonusOptions,
  onConfirmBonusOptionsTitle,
  repeatingTimeoffs,
  close
}) => {
  const [onConfirmBonusOptionSelected, setOnConfirmBonusOptionSelected] = useState(onConfirmBonusOptions?.length ? 0 : null)
  const isWideModal = recordsList && recordsList.length

  return (
    <Modal
      size={isTallModal
        ? Modal.SIZES.M
        : isWideModal
          ? Modal.SIZES.L
          : Modal.SIZES.S}
      className='ds-confirm'
      footerContent={
        <Flex grow={1}>
          <Button
            size={isWideModal ? Button.SIZES.MEDIUM : Button.SIZES.FULL}
            onClick={() => {
              onCancel()
              close()
            }}
            label={cancelLabel}
          />
          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            style={Button.STYLES.CONTAINED}
            size={isWideModal ? Button.SIZES.MEDIUM : Button.SIZES.FULL}
            onClick={() => {
              onConfirm(onConfirmBonusOptions?.length ? onConfirmBonusOptions[onConfirmBonusOptionSelected]?.onConfirmFnParamsObject : undefined)
              close()
            }}
            label={confirmLabel}
            testid='confirm-button'
            color={buttonColor === 'red' ? Button.COLORS.RED : (buttonColor === 'green' ? Button.COLORS.GREEN : Button.COLORS.PRIMARY)}
          />
        </Flex>
      }
      headerContent={
        <Text
          type={Text.TYPES.BODY_LARGE}
          color={Text.COLORS.BLACK}
          weight={Text.WEIGHTS.BOLD}
        >
          {React.isValidElement(title) ? title : t(title)}
        </Text>
      }
      sections={[
        <Fragment key={0}>
          {/* subtitle wrapped in Alert component */}
          {subtitle ? (
            <Alert
              text={React.isValidElement(subtitle) ? subtitle : t(subtitle)}
              type={type}
              size={isWideModal ? Alert.SIZES.FULL_WIDTH : Alert.SIZES.SMALL}
            />
          ) : null}
          {/* list of relevant records in a Table, if it was provided */}
          {recordsList && recordsList.length ? (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} />
              <RecordsList records={recordsList} repeatingTimeOffs={repeatingTimeoffs} />
            </>) : null}

          {/* radio buttons representing the passed 'onConfirmBonusOptions' */}
          {onConfirmBonusOptions && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} />
              <RadioGroup
                value={onConfirmBonusOptionSelected}
                onChange={(v) => setOnConfirmBonusOptionSelected(v)}
                options={onConfirmBonusOptions.map((v, i) => { return { label: v.label, value: i } })}
                label={onConfirmBonusOptionsTitle}
                direction={RadioGroup.DIRECTIONS.VERTICAL}
              />
            </>
          )}

          {/* main content / text */}
          {React.isValidElement(text)
            ? text
            : t(text)}
        </Fragment>
      ]}
    />)
}

Confirm.propTypes = {
  // required:
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  type: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,

  // optional:
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonColor: PropTypes.string,
  recordsList: PropTypes.arrayOf(PropTypes.object),
  isTallModal: PropTypes.bool,
  onConfirmBonusOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string, // label displayed next to radio button
    onConfirmFnParamsObject: PropTypes.object // an object that gets sent as a parameter to onConfirm() function when this radio button is selected
  })),
  onConfirmBonusOptionsTitle: PropTypes.string
}
Confirm.defaultProps = {
  title: ' ',
  onConfirm: () => null,
  confirmLabel: ' ',
  subtitle: null,
  text: null,
  type: 'warning',
  isTallModal: false,
  buttonColor: 'primary',
  cancelLabel: t('CANCEL'),
  onCancel: () => {},
  recordsList: null,
  onConfirmBonusOptions: null,
  onConfirmBonusOptionsTitle: null
}

export default Confirm
